import axios from 'axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.API_ROOT,
  timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    // const data = error.response.data
    // const userToken = localStorage.getItem('userToken')
    // if (data.status!==1) {
    //   notification.error({
    //     message: data.msg,
    //   })
    // }

  }
  return Promise.reject(error)
}

request.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

  return config
}, errorHandler)


// response interceptor
request.interceptors.response.use((response) => {
  if (response.data.code === 200) {
    return response.data
  }else{
    return response.data
  }
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}