import Vue from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment'

Vue.config.productionTip = false
import 'lib-flexible'

import echarts from "echarts";
Vue.prototype.$echarts = echarts;

// 时间转换
Vue.filter('momentTime', (e)=>{
  return moment(e*1000).format('HH:mm')
})

// import 'swiper/swiper-bundle.css';


import AMapLoader from "@amap/amap-jsapi-loader";


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);



new Vue({
  router,
  el: '#app',
  render: h => h(App)
}).$mount('#app')
