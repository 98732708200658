<template>
   <div :style="{'background-image': `url(${require('@/assets/images/bg.png')})`}" class="home_bg">
      <div class="header_box align-center">
          <div class="title">{{hotelScreen.title}}</div>
          <div class="right_info align-center">
             <span class="time">{{currentTime}}</span>
              <span class="city">{{hotelScreen.city}}</span>
              <div class="date">
                <span>{{date}}</span>
                <span>{{weekOfDay}}</span>
              </div>
              <span class="wen">{{weatherData.temperature}}</span>
              <span class="wether">{{weatherData.weather}}</span>
          </div>
      </div>

      <div class="content_box">
        <div class="left_box">
            <div class="room_list">
              <div class="item">
                 <div>房型</div>
                 <div>今日房价</div>
              </div>
              <div class="item" v-for="(item,index) in roomDetail" :key="index">
                 <div>{{item.title}}</div>
                 <div>{{item.price}}</div>
              </div>
            </div>
            <div class="tips_room_init">
                 <div class="tips_room"  v-if="hotelScreen.remark" >
               <span>{{hotelScreen.remark}}</span>
            </div>
            </div>
           
            <div class="code_info center">
                <img :src="'https://admin.jymxhotel.com/prod-api/'+hotelScreen.qrCode" class="code"/>
                <div class="contact_box">
                   <div class="contact_num align-center">
                      <img src='~@/assets/images/tel.png'/>
                      <div class="num_info">
                         <div>预定 {{hotelScreen.phone}}</div>
                         <div>电话 {{hotelScreen.phoneBak}}</div>
                      </div>
                   </div>
                   <div class="wx_box align-center">
                      <img src='~@/assets/images/scan.png'/>
                      <span>扫码预定房间，享“微信订房”专属价</span>
                   </div>
                </div>
            </div>
        </div>
        <div class="right_box">
             
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in bannerData" :key="index">
                        <div :style="{'background-image': `url(${'https://admin.jymxhotel.com/prod-api/'+item})`}" class="banner_box"></div>
                    </div>
                </div>
                <div class="swiper-pagination" slot="pagination"></div>
             </div>
             <div class="icon_box align-ccenter">
                <div class="item">
                    <img src='~@/assets/images/icon1.png'/>
                    <span>无线网络</span>
                    <span>WIFI</span>
                </div>
                 <div class="item">
                    <img src='~@/assets/images/icon2.png'/>
                    <span>叫醒服务</span>
                    <span>Wake up</span>
                </div>
                 <div class="item">
                    <img src='~@/assets/images/icon3.png'/>
                    <span>早餐服务</span>
                    <span>breakfast</span>
                </div>
                 <div class="item">
                    <img src='~@/assets/images/icon4.png'/>
                    <span>多功能插座</span>
                    <span>socket</span>
                </div>
                 <div class="item">
                    <img src='~@/assets/images/icon5.png'/>
                    <span>雨伞</span>
                    <span>umbrella</span>
                </div>
             </div>
        </div>
      </div>
   </div>

   
</template>

<script>
import Swiper from 'swiper'
import "swiper/css/swiper.css";
import moment from "moment"
import{
		weatherInfo,
    hotelInfo,
    roomsInfo
	}from '@/api/index'
export default {
  name: 'Home',
  components: {
   
  },
   data () {
    return {
      weatherData:{},
      hotelScreen:{},
      roomDetail:[],
      date:'--/--/--',
      weekOfDay:'',
      currentTime:'--:--',
      bannerData:[]
    }
  },
  mounted(){ 
   var that = this

  

    this.getBaseInfo()
  
    this.getCurrentTime()

  },
  methods:{

    getUrlKey (name) {
    return decodeURIComponent(
      // eslint-disable-next-line no-sparse-arrays,standard/array-bracket-even-spacing
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [ , ''])[1].replace(/\+/g, '%20')) || null
    },
    getCurrentTime() {
        var _this = this;
        _this.currentTime = moment().format('HH:mm');
      
       setInterval(function(){
    　　  _this.currentTime = moment().format('HH:mm');
         if (moment().format('HH:mm:ss')=='00:00:01'){
            _this.getBaseInfo()
         }
       },1000) 

        setInterval(function(){
         _this.getRoomsInfo()
       },60000) 
    },

 
    getBaseInfo () {


        moment.locale('zh-cn');
    this.date = moment().format('YYYY/MM/DD');
    this.weekOfDay = moment().format('dddd')

       hotelInfo({id:this.getUrlKey('id')}).then((res) => {
        if (res.code === 200) {
           this.hotelScreen = res.data.hotelScreen
           this.bannerData = res.data.hotelScreen.imgs.split(',')

            setTimeout(function(){
               new Swiper(".swiper-container", {
                    loop: true,
                    autoplay: true,
                    effect: 'fade',
                    disableOnInteraction: false,
                    pagination : {
                      el:'swiper-pagination',
                      clickable: true
                    },        
        });
            },100)

           this.getWeather(res.data.hotelScreen.city)
           this.roomDetail = res.data.roomDetail
        }
      })
    },
   getWeather(city){
     weatherInfo({city:city}).then((res) => {
        if (res.code === 200) {
          this.weatherData = res.data.future[0]
        }
      })
   },
    getRoomsInfo(){
     roomsInfo({id:this.getUrlKey('id')}).then((res) => {
        if (res.code === 200) {
         this.roomDetail = res.data
        }
      })
   }
    //jw_chart
    //
  }
}
</script>
<style lang="less">
   @import '../assets/css/index.less';
   .tips_room{

	box-sizing: border-box;
	word-break: break-all;
	white-space: nowrap;
	overflow: hidden;
  span{
    display: inline-block;
	padding-left: 100%;
	/* 从右至左开始滚动 */
	animation: marqueeTransform 16s linear infinite;
  }
   }

   @keyframes marqueeTransform {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(-100%, 0);
	}
}
</style>



