import request from '@/utils/request'


// 获取天气信息
export function weatherInfo (parameter) {
  return request({
    url: '/prod-api/data/weather/info',
    method: 'get',
    params: parameter
  })
}


// 获取基础信息
export function hotelInfo (parameter) {
  return request({
    url: '/prod-api/data/hotel/info',
    method: 'get',
    params: parameter
  })
}

// 获取房型价格
export function roomsInfo (parameter) {
  return request({
    url: '/prod-api/data/rooms/info',
    method: 'get',
    params: parameter
  })
}
